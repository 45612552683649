.content {
  display: flex;
  flex-direction: row;
  width: 100%;

  .noticeHome {
    color: white;
    width: 40%;
    margin: 1%;
    float: left;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    background: #222222;
    border-top: 2px solid var(--color-red);

    .listNotices {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 1rem;

      .listItem {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: var(--font-size-lg);
        line-height: 42px;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        .notice {
          cursor: pointer;

          p {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
          }
        }
      }

      svg {
        color: var(--color-red);
      }
    }
  }

  .banners {
    width: 20%;
    margin: 1%;
    align-self: center;

    > div {
      height: 100%;
      width: 100%;
    }

    img {
      max-height: 10rem;
      width: auto;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1300px) {
  .content {
    .notice {
      cursor: pointer;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 18.6rem;
        display: block;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .content {
    display: none;
  }
}
