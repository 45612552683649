.menuLeft {
            display: flex;
            align-items: center;
            gap: 2rem;

            .logoMenu {
                padding: var( --logo-padding-desktop, 1rem);
                width: var(--logo-size, 250px);
                text-align: center;

                a {
                    img {
                        // @include imgw;
                        max-width: 100%;
                        // max-height: 55px;
                        cursor: pointer;
                    }
                }
            }
        }
        
.boxNavigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6rem;

  // border: 1px solid var(--color-red);
  // border-radius: 1rem;

  padding: 1rem;
  width: 100%;

  .itemContainer {
    width: 100%;
    height: 5rem;
    border-radius: 1.3rem;
    transform: skew(-10deg);
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 3px var(--color-red);
    background: rgb(7,18,14);
    // background: linear-gradient(180deg, rgba(7,18,14,1) 0%, rgba(7,18,14,1) 49%, rgba(44,59,53,1) 85%, rgba(28,59,48,1) 100%);

    p,a {
      padding-left: 2.3rem;
      transform: skew(10deg);
      display: grid;
      grid-template-columns: 35px 1fr;
      align-items: center;
      gap: 0.3rem;

      color: var(--white);
      cursor: pointer;

      font-size: 1.8rem;

      line-height: 4.5rem;
      width: 100%;
      transition: all 0.35s ease;

      img, svg {
        max-width: 3rem;
        max-height: 3rem;
        object-fit: cover;
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        color: var(--color-red);

        img, svg {
          transform: rotate(-30deg);
        }
      }
    }
  }
  p:last-child {
    border-bottom: initial;
  }
}

.navigationGrid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 0.2rem;

  .subMenuGridItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--secondary-color);
    padding: 1rem 0 1rem 1rem;
    cursor: pointer;
    font-size: 1.1rem;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }

    .titleActive {
      color: var(--yellow-color);
    }

    &:not(.firstRow):hover {
      background: var(--primary-color);
    }
  }

  .firstRow {
    background-color: var(--yellow-color);
    background-image: var(--navigation-sports-gradient);

    &:hover {
      opacity: 0.8;
    }
  }
}

// > :last-child {
//   border-bottom: 1px solid white;
// }

@media (max-width: 1600px) {
}

@media only screen and (max-width: 1024px) {
  .navigationSportsTwoMobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    background-color: var(--body-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    overflow-x: auto;

    .container {
      position: relative;

      display: flex;
      flex-direction: column;

      // padding: 3rem;
      width: 100%;
      //   height: 80%;
      height: 100%;

      .content {
        overflow-x: auto;
        padding-bottom: 28rem;

        .child {
          //background-color: red;
        }
      }
    }
  }
}
