.imagesLink {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 30rem;
  width: fit-content;

  margin-bottom: 0.5rem;
  div {
    height: 33%;

    .imageLink {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .imagesLink {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    padding-bottom: 4rem;
    padding: 0 4rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
