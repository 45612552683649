@import "../bet-nextv1/styles/globals.scss";

:root {
  --color-button: white;

  --body-color: black;

  --background-color-modal-login: black;
  --background-color-modal-message: black;
  --background-color-button: #2f756d;
  --background-color-loading: #000000;
  --background-color-notice: #2f756d;
  --background-color-body: black;
  --background-color-header: black;
  --background-color-button-carousel: #2f756d;

  --border-loading: #2f756d;
  --border-color: #2f756d;

  --color-red: #2f756d;
  --color-more: white;
  --color-loading: white;
  --color-svg-carousel: white;

  --secondary-color: black;

  --yellow-color: #2f756d;
  --turnover-value-color: white;
  --button-two: #2f756d;

  --depositBonusItemBorder: 3px solid var(--color-#2f756d);
  --depositBonusDescTextColor: var(--color-#2f756d);

  --providers-thumbs-width: 16.6%;

  --font-bold-light: 500;
  --font-mobile-adjustement: 2px;

  --logo-size: 22rem;
  --logo-size-mobile: 30rem;
  --logo-height-mobile: 7rem;
  --logo-padding-desktop: 0rem;

  --navigation-sports-two-menu-gap: 0rem;

  --headerThreeMinHeight: 6rem;
  --headerThreePaadingMobile: 0.5rem 1rem;
}

body {
  font-family: "EsaManru";
  font-weight: 300;
  text-decoration-style: solid;

  p,
  a,
  button {
    font-family: "EsaManru";
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-family: "EsaManru";
    font-weight: 500;
  }
  p,
  a {
    font-weight: 300;
  }
}

.layoutPages {
  background-color: black;
}

.layoutRight {
  max-width: 30rem !important;
  background-color: black;
}

.mystyle {
  padding: 10px;
}

.middle {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "EsaManru";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/font/esamanruLight.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "EsaManru";
  font-weight: 500;
  font-style: normal;
  src:  url("/assets/font/esamanruMedium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "EsaManru";
  font-weight: 700;
  font-style: normal;
  src:  url("/assets/font/esamanruBold.ttf") format("truetype");
  font-display: swap;
}
@media only screen and (hover: none) and (pointer: coarse) {
  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }
}
