.modalVideo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 90vh;
    padding-bottom: 1rem;
    background: black;
    border-radius: 5px;

    .modalHeader {
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: row;
      z-index: 1;
      justify-content: flex-end;
      top: 0;
      right: -2rem;

      .closeModalSvg {
        color: black;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        font-size: 2.5rem;
        padding: 0.5rem 0;
        margin-top: 0.5rem;
      }
    }

    .modalBody {
      width: 100%;
      height: 100%;
      padding: 1rem;

      .iframe {
        position: relative;
        // padding-bottom: 56.25%; /* 16:9 */
        padding-bottom: 56.25%; /* 16:9 */
        width: 100%;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 80%;
          border: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .modalVideo {
    .container {
      width: 90%;
    }
  }
}
