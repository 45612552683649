.loading {
    :global(.MuiPaper-root) {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: var(--border-radius);
        opacity: 0.8;
    }

    .splash {
        padding: 1rem;
        position: relative;
        // background: alpha($color: #000000);
        background: var(--background-color-loading, #000000);
        height: 100%;
        // z-index: 9999999;
        // height: 100vh;
        // width: 100%;
        color: white;

        .content {
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: white;

            .loader {
                border: 5px solid var(--border-loading, #fdb82c);
                border-radius: 50%;
                border-top: 5px solid var(--color-loading, #f3486a);
                width: 100px;
                height: 100px;
                -webkit-animation: spin 1s linear infinite;
                /* Safari */
                animation: spin 1s linear infinite;
                margin: auto;
                left: 0;
                right: 0;
                top: calc(50% - 50px);
                // top: 390%;
                // bottom: 0;
                position: absolute;
            }
        }

        p {
            position: absolute;
            right: 0;
            // left: calc(50% - 41px);
            top: calc(50% + 100px);
            font-weight: var(--font-bold-light);
            font-size: 23px;
        }
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.center {
    border: none !important;
    text-align: center;
}

@media only screen and (max-width: 1441px) {
    .splash {
        > p {
            right: 45.8%;
        }
    }
}
