.tabLinks {
  display: flex;
  align-items: center;
  width: 100%;
  // gap: 2rem;
  margin: 0 1%;
  height: 5rem;
  min-height: 5rem;
  border-radius: 10px;
  border: none;
  background: #212121;
  padding: 0 20px 0 30px;
  font-family: "EsaManru";
  font-weight: 500;
  font-size: 2.6rem;
  color: white;
  text-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  span {
    margin: 0px;
    padding: 0px;
    border: 0px;
    box-sizing: border-box;
    user-select: none;
    -webkit-user-drag: none;
    font-size: 2rem;
    outline: none;
    margin-left: 0.5rem;
  }

  &:hover {
    @extend .selected;
  }

  img {
    width: unset !important;
    height: unset !important;
    display: inline-block;
    margin: 0 0 0 auto;
    filter: grayscale(100%) brightness(0.4);
    transition: all 0.5s;
  }

  .filler {
    width: 100%;
    margin-left: auto;
    flex-grow: 1;
  }
}

.selected {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 3px var(--color-red);
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 2%, var(--color-red) 63%);

  img {
    filter: unset;
  }
}

.titleInformation {
  display: flex;
  align-items: center;
  gap: 1rem;

  background-color: var(--background-color-notice);
  background-image: var(--event-list-gradient);
  border-radius: 0.5rem;

  color: var(--white);
  font-size: 1.7rem;

  margin: 2rem 1rem;
  padding: 1rem;
  width: 42%;
  //width: 100%;

  img {
    max-width: 2rem;
    max-height: 2rem;
    object-fit: cover;
  }

  .more {
    color: var(--color-more);
    margin-left: auto;
    cursor: pointer;

    svg {
      height: 1rem;
      width: auto;
    }
  }

  svg {
    font-size: 2rem;
  }

  span {
    color: var(--yellow-color);
    font-size: 1.4rem;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  > div {
    width: var(--providers-thumbs-width) !important;
  }

  & {
    padding: 0 !important;
    margin: 1rem;
  }
  & :hover {
    animation: anim 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    transform: scale(1);
  }
}

.cardsCasino {
  > div {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 1rem;
    }

    & {
      padding: 0 !important;
      margin: 1rem;
    }
    & :hover {
      animation: anim 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
      transform: scale(1);
    }

    > div {
      width: var(--providers-thumbs-width) !important;
    }
  }

  .noAnimation {
    & :hover {
      animation: none !important;
      transform: none !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tabLinks {
    padding: 0;
    font-size: var(--font-size-lg);

    .filler {
      display: none;
    }
    span {
      font-size: var(--font-size-md);
    }
  }

  .cardsCasino {
    > div {
      > div {
        width: 33% !important;
      }
    }
  }

  .items {
    > div {
      width: 33% !important;
    }
  }
}

@keyframes anim {
  0% {
    -webkit-transform: translateX(0px) rotateY(0deg) skew(0deg, 0deg);
    transform: translateX(0px) rotateY(0deg) skew(0deg, 0deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    filter: brightness(1);
  }
  5% {
    -webkit-transform: translateX(0px) rotateY(1deg) skew(1deg, 0deg);
    transform: translateX(0px) rotateY(1deg) skew(1deg, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    filter: brightness(1.2);
  }
}
