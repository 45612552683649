.cardLive {
  //width: 25%;
  float: left;
  padding: 10px;
  cursor: pointer;
  width: var(--providers-thumbs-width, 25%);
  display: flex;
  align-items: center;
  justify-content: center;

  .cardLiveBox {
     position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
      display: block;
      width: 100%;

      background: unset;
      border: unset;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }
    }

    &:hover {
      .cardLiveOverlay {
        inset: 0 0 0 0%;
      }
    }

    .cardLiveBoxLabel {
      color: #fff;
      font-size: 1rem;
      margin: 1rem 0;
    }

    .overlay {
      position: absolute;
      padding: 1rem;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.534);
      cursor: auto;

      span {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        // width: 50%;
        color: rgb(255, 255, 255);
      }
    }
  }
}

.hoverEffect {
  transition: transform 0.4s ease-in-out;
}

.hoverEffect:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 1024px) {
  .cardLive {
    width: 50%;
  }
}
